import React, { useRef } from "react";
import Slider from "react-slick";
import card1 from "../assets/2.png";
import card2 from "../assets/3.png";
import card3 from "../assets/4.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Blogs() {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false, // Hide default arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cards = [
    {
      id: 1,
      img: card1,
      title: "Understanding Demat Accounts",
      subtitle: "About a Demat Account",
      description:
        "A Demat account, short for 'dematerialized account,' is an electronic account used to hold and manage shares and securities in a digital format...",
    },
    {
      id: 2,
      img: card2,
      title: "Avoiding Pitfalls for Successful Investing",
      subtitle: "Common Mistakes to Avoid",
      description:
        "One major mistake is failing to diversify investments, which increases risk if one stock performs poorly...",
    },
    {
      id: 3,
      img: card3,
      title: "Safeguarding Your Investments",
      subtitle: "Risk Management Strategies",
      description:
        "Effective risk management is crucial for trading success. Diversify your investments to spread risk, use stop-loss orders...",
    },
    // Add more cards up to 10
  ];

  return (
    <section className="flex flex-col justify-center max-w-7xl min-h-screen px-4 py-10 mx-auto sm:px-6">
      <div className="flex flex-wrap items-center justify-between mb-8">
        <h2 className="mr-10 text-4xl font-bold leading-none md:text-5xl">
          Things to <span className="text-[#ffbf00]">know</span>
        </h2>
      </div>

      <Slider ref={sliderRef} {...settings}>
        {cards.map((card) => (
          <div key={card.id} className="px-4">
            <div className="flex flex-col">
              <img
                src={card.img}
                alt="Card img"
                className="object-cover object-center w-full h-48"
              />
              <div className="flex flex-grow">
                <div className="triangle"></div>
                <div className="flex flex-col justify-between px-4 py-6 bg-white border border-gray-400">
                  <div>
                    <a
                      href="##"
                      className="inline-block mb-4 text-xs font-bold capitalize border-b-2 border-blue-600 hover:text-blue-600"
                    >
                      {card.title}
                    </a>
                    <a
                      href="##"
                      className="block mb-4 text-2xl font-black leading-tight hover:underline hover:text-blue-600"
                    >
                      {card.subtitle}
                    </a>
                    <p className="mb-4">{card.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {/* Buttons Below the Cards */}
      <div className="flex justify-start px-2 mt-8 space-x-3">
        <button
          className="bg-blue-600 text-white rounded-full p-3"
          onClick={() => sliderRef.current.slickPrev()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          className="bg-blue-600 text-white rounded-full p-3"
          onClick={() => sliderRef.current.slickNext()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </section>
  );
}

export default Blogs;
