import React from "react";

function Services() {
  return (
    <section>
      <div className="bg-slate-900 p-4 min-h-screen border-b-8 border-[#ffbf00]">
        <div
          aria-hidden="true"
          className="absolute inset-0 h-max w-full m-auto grid grid-cols-2 -space-x-52 opacity-20"
        >
          <div className="blur-[106px] h-56 bg-gradient-to-br to-purple-400 from-blue-700"></div>
          <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-indigo-600"></div>
        </div>
        <div className="max-w-7xl mx-auto px-2 md:px-12 xl:px-4">
          <div className="md:w-2/3 lg:w-1/2 mt-12 text-gray-100">
            <h2 className="my-8 text-2xl font-bold text-white md:text-5xl">
              Our <span className="text-[#ffbf00]">Services</span>
            </h2>
            <p className="text-gray-300">
              We have built many services and some of them are below
            </p>
          </div>
          <div className="mt-16 grid divide-x divide-y divide-gray-700 overflow-hidden rounded-3xl border text-gray-600 border-gray-700 sm:grid-cols-2 lg:grid-cols-4 lg:divide-y-0 xl:grid-cols-4">
            <div className="group relative bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
              <div className="relative space-y-8 py-12 p-8">
                <img
                  src="https://www.svgrepo.com/show/164986/logo.svg"
                  loading="lazy"
                  width="200"
                  height="200"
                  className="w-12 h-12 rounded-full"
                  style={{ color: "transparent" }}
                  alt="img"
                />
                <div className="space-y-2">
                  <h5 className="text-xl font-semibold text-white transition group-hover:text-secondary">
                    NRI Investing
                  </h5>
                  <p className="text-gray-300">
                    NRIs are welcome to open accounts with Mind Matrix.
                    Documents and Other Details
                  </p>
                </div>
              </div>
            </div>
            <div className="group relative bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
              <div className="relative space-y-8 py-12 p-8">
                <img
                  src="https://www.svgrepo.com/show/120853/logo.svg"
                  loading="lazy"
                  width="200"
                  height="200"
                  className="w-12 h-12 rounded-full"
                  style={{ color: "transparent" }}
                  alt="img"
                />
                <div className="space-y-2">
                  <h5 className="text-xl font-semibold text-white transition group-hover:text-secondary">
                    Discount Broking
                  </h5>
                  <p className="text-gray-300">
                    Discount Broking is our primary product and what we
                    specialise in.
                  </p>
                </div>
              </div>
            </div>
            <div className="group relative bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
              <div className="relative space-y-8 py-12 p-8">
                <img
                  src="https://www.svgrepo.com/show/120852/logo.svg"
                  loading="lazy"
                  width="200"
                  height="200"
                  className="w-12 h-12 rounded-full"
                  style={{ color: "transparent" }}
                  alt="img"
                />
                <div className="space-y-2">
                  <h5 className="text-xl font-semibold text-white transition group-hover:text-secondary">
                    Premium Broking
                  </h5>
                  <p className="text-gray-300">
                    With Mind Matrix premium broking service, you'll receive the
                    dedicated personal attention while investing. Higher limits
                    and Personalised Services
                  </p>
                </div>
              </div>
            </div>
            <div className="group relative bg-gray-800 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
              <div className="relative space-y-8 py-12 p-8">
                <img
                  src="https://www.svgrepo.com/show/120850/logo.svg"
                  loading="lazy"
                  width="200"
                  height="200"
                  className="w-12 h-12 rounded-full"
                  style={{ color: "transparent" }}
                  alt="img"
                />
                <div className="space-y-2">
                  <h5 className="text-xl font-semibold text-white transition group-hover:text-secondary">
                    Research Services
                  </h5>
                  <p className="text-gray-300">
                    Mind Matrix is your stock broker who assists you in your
                    investment decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
