import React from "react";
import featuresbg from "../assets/features-bg.png";

function FeaturesForm() {
  return (
    <div>
      <section class="bg-white">
        <div class="grid max-w-screen-xl px-4 pt-16 pb-10 mx-auto lg:gap-8 xl:gap-0 lg:py-12 lg:grid-cols-12 lg:pt-20">
          <div class="mr-auto place-self-center lg:col-span-7">
            <h1 class="max-w-2xl mb-8 md:mb-6 lg:mb-6 text-4xl font-bold leading-none tracking-tight md:text-5xl xl:text-6xl ">
              <span className="text-[#ffbf00]">About</span> Us
            </h1>

            <p class="max-w-2xl mb-6 font-light text-gray-700 lg:mb-8 md:text-lg lg:text-xl">
              Mind Matrix is an Authorized person (Sub broker) of Axis
              securities. Axis Securities is a proud subsidiary of Axis Bank -
              India's 3rd largest private sector bank and the choice of more
              than 20 million clients.
              <br />
              <br />
              Mind Matrix is providing trading facilities in Equity Cash &
              Derivatives , Commodity derivatives, currency derivatives for the
              exchanges NSE,BSE,MCX,NCDEX with cutting edge technologies
              including artificial intelligence .
            </p>

            <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <a
                href="/"
                target="_blank"
                class="inline-flex items-center justify-center w-full px-5 py-3 text-sm font-medium text-center text-gray-200 border border-gray-200 rounded-lg sm:w-auto hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 bg-[#022476]"
              >
                Contact Us Today
              </a>
            </div>
          </div>

          <div class="mt-8 lg:mt-0 md:mt-0 xl:mt-0 lg:col-span-5 lg:flex">
            <img src={featuresbg} alt="hero" className="h-full w-auto" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default FeaturesForm;
