import React from "react";
import bg from "../assets/hero-bg.png";
import MobileInput from "./MobileInput";

function Hero() {
  return (
    <div>
      <section class="w-full">
        <div class="w-full h-[520px] relative flex flex-col justify-center items-center">
          <img src={bg} alt="Background" class="w-full h-full object-cover " />
          <div class="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-40">
            <div>
              <h1 class="text-white text-center xl:text-6xl lg:text-4xl md:text-3xl text-2xl font-bold p-2 rounded-sm">
                Mind <span className="text-[#ffbf00]">Matrix</span>
              </h1>
            </div>
            <div>
              <h3 class="text-white text-center xl:text-2xl lg:text-xl md:text-xl text-md font-normal p-2 rounded-sm underline underline-offset-8 ">
                Expertise you can trust !
              </h3>
            </div>
            <div class="w-full mx-auto z-50">
              <MobileInput />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Hero;
