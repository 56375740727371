import React, { useState } from "react";
import { motion } from "framer-motion";

// Importing images
import img1 from "../assets/imgl-1.jpg";
import img2 from "../assets/imgl-2.jpg";
import img3 from "../assets/imgl-3.jpg";
import img4 from "../assets/imgl-4.jpg";
import img5 from "../assets/imgl-5.jpg";
import img6 from "../assets/imgl-6.jpg";

const images = [img1, img2, img3, img4, img5, img6];

const Gallery = () => {
  const [isHovered, setIsHovered] = useState(false);
  //   const controls = useAnimation();

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, ease: "easeOut" }}
      className="md:min-h-screen lg:min-h-screen xl:min-h-screen h-auto md:py-0 lg:py-0 xl:py-0 py-2 md:mb-0 lg:mb-0 xl:mb-0 mb-6 flex flex-col justify-center items-center"
    >
      <h1 class="max-w-2xl mb-8 md:mb-6 lg:mb-6 text-4xl font-bold leading-none tracking-tight md:text-5xl xl:text-6xl ">
        <span className="text-[#ffbf00]">Work</span> Culture
      </h1>
      <p class="max-w-4xl mb-8 text-lg text-gray-900 leading-relaxed font-light text-center p-2">
        We’re all about teamwork, creativity, and having fun together. Everyone
        here is encouraged to share their ideas and grow, and we make sure to
        celebrate our wins as a team.
      </p>
      <br />
      <div
        className="relative overflow-hidden w-full"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <motion.div
          className="flex space-x-8"
          animate={isHovered ? { x: 0 } : { x: ["0%", "-100%"] }}
          transition={{
            duration: 35,
            repeat: Infinity,
            ease: "linear",
          }}
        >
          {images.concat(images).map((img, index) => (
            <motion.div
              key={index}
              className="flex-shrink-0"
              whileHover={{
                scale: 1.1,
                rotate: 5,
                boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.3)",
              }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              <div className="bg-white shadow-xl rounded-xl overflow-hidden">
                <motion.img
                  src={img}
                  alt={`Gallery ${index + 1}`}
                  className="w-full h-96 object-cover rounded-xl"
                  initial={{ scale: 1 }}
                  whileHover={{ scale: 1.0, rotate: 3 }}
                  transition={{ duration: 1 }}
                />
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Gallery;
