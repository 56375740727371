import React from "react";
import logo from "../assets/mindmatrix.png";

function Footer() {
  return (
    <footer className="bg-gray-100 text-gray-800 border-t border-gray-300">
      <div class="max-w-8xl mx-auto py-4">
        <div class="container px-4 mx-auto">
          <div class="-mx-4 flex flex-wrap justify-between">
            <div class="px-4 my-2 w-full lg:max-w-md ">
              <a href="/" class="block mb-5 px-3">
                <img
                  src={logo}
                  alt=""
                  className="md:h-16 lg:h-20 xl:h-32 h-20 w-auto object-contain"
                />
              </a>
              <ul class="space-y-2 text-md font-normal text-gray-800   md:p-4 lg:p-3 xl:p-3">
                <li className="gap-2 flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                    />
                  </svg>
                  Address 310- sky corporate park A.b road Indore
                </li>
                <li className="gap-2 flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                    />
                  </svg>
                  <span className="underline underline-offset-4 cursor-pointer">
                    mindmatrixbroking@gmail.com
                  </span>
                </li>
              </ul>
            </div>

            <div class="px-4 my-4 w-full sm:w-auto xl:w-1/5">
              <div>
                <h2 class="inline-block text-2xl pb-4 mb-4 border-b-4 border-blue-600">
                  Connect With Us
                </h2>
              </div>
              <a
                href="/"
                class="inline-flex items-center justify-center h-8 w-8 border border-gray-400 rounded-full mr-1 hover:text-blue-400 hover:border-blue-400"
              >
                <svg
                  class="w-4 h-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                </svg>
              </a>
              <a
                href="/"
                class="inline-flex items-center justify-center h-8 w-8 border border-gray-400 rounded-full mr-1 hover:text-blue-400 hover:border-blue-400"
              >
                <svg
                  class="w-4 h-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                </svg>
              </a>
              <a
                href="/"
                class="inline-flex items-center justify-center h-8 w-8 border border-gray-400 rounded-full mr-1 hover:text-blue-400 hover:border-blue-400"
              >
                <svg
                  class="w-4 h-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                </svg>
              </a>
            </div>
          </div>

          <p className="py-4 text-md font-light text-gray-800 px-2">
            Disclaimer: Investment in securities market are subject to market
            risks, read all the related documents carefully before investing.
            Brokerage will not exceed SEBI prescribed limit. Please refer the
            Risk Disclosure Document issued by SEBI and go through the Rights
            and Obligations and Do's and Dont's issued by Stock Exchanges and
            Depositories before trading on the Stock Exchanges. Before investing
            in the asset class consider your investment objectives, level of
            experience and risk appetite carefully.
          </p>
        </div>
      </div>
      <div class="bg-[#001c5e] py-4 text-gray-200 ">
        <div class="container px-4 max-w-8xl mx-auto">
          <div class="-mx-4 flex flex-wrap justify-between font-light">
            <div class="px-4 w-full text-center sm:w-auto sm:text-left">
              Copyright © 2023 MindMatrix. All Rights Reserved.
            </div>
            <div class="px-4 w-full text-center sm:w-auto sm:text-left">
              <a href="https://edumitram.com" target="_blank" rel="noreferrer">
                Powered By{" "}
                <span className="text-orange-500 hover:underline hover:underline-offset-4">
                  Edumitram
                </span>{" "}
                Team
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
