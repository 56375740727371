import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiendpoint from "../apiendpoint.json";

const Contact = () => {
  const [formData, setFormData] = useState({
    NameUser: "",
    Email: "",
    Subject: "",
    Message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch(apiendpoint.submitUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Form submitted successfully!");
          setFormData({
            NameUser: "",
            Email: "",
            Subject: "",
            Message: "",
          });
        } else {
          toast.error("Failed to submit the form. Please try again.");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ToastContainer />
      <form
        onSubmit={handleSubmit}
        className="bg-slate-900 border-t-4 border-b-4 border-[#ffbf00]"
      >
        <section className="text-gray-600 body-font relative max-w-8xl mx-auto">
          <div className="container md:px-8 lg:px-8 xl:px-8 px-3 md:py-20 lg:24 xl:24 py-5 mx-auto flex sm:flex-nowrap flex-wrap">
            <div className="lg:w-2/3 md:w-1/2 bg-none overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.034918292231!2d75.89622167530611!3d22.764085379355866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396302b2bcecbfbd%3A0x482f4b02153441b2!2sSkye%20Corporate%20Park!5e0!3m2!1sen!2sin!4v1723451164492!5m2!1sen!2sin"
                width="90%"
                height="100%"
                marginHeight="0"
                title="Mindmatrix"
                className="absolute inset-0 rounded-lg"
                allowfullscreen=""
                frameBorder="0"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                <div className="lg:w-1/2 px-6">
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                    ADDRESS
                  </h2>
                  <p className="mt-1">
                    310- sky corporate park A.b road Indore, MP
                  </p>
                </div>
                <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                    EMAIL
                  </h2>
                  <a
                    href="mailto:mindmatrixbroking@email.com"
                    className="text-[#001c5e] underline underline-offset-4 leading-relaxed"
                  >
                    mindmatrixbroking@email.com
                  </a>
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                    PHONE
                  </h2>
                  <p className="leading-relaxed">+91 92011 01499</p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 md:w-1/2 bg-slate-900 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
              <h2 className="text-gray-100 md:text-3xl lg:text-4xl text-2xl mb-1 font-extrabold title-font">
                <span className="text-[#ffbf00]">Reach</span> out to us
              </h2>
              <p className="leading-relaxed mb-5 text-gray-200">
                Our team is avialble from 10 AM - 8 PM (Monday to Saturday)
              </p>
              <div className="relative mb-4">
                <label
                  htmlFor="NameUser"
                  className="leading-7 text-sm font-medium text-gray-200"
                >
                  Name
                </label>
                <input
                  type="text"
                  placeholder="Mention your name here"
                  id="NameUser"
                  name="NameUser"
                  value={formData.NameUser}
                  onChange={handleChange}
                  className="w-full bg-slate-700 rounded  focus:border-[#ffbf00] focus:ring-2 focus:ring-[#ffbf00]/30 text-base outline-none text-white py-1 placeholder:text-sm placeholder:text-gray-300 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="Email"
                  className="leading-7 text-sm font-medium text-gray-200"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="Email"
                  name="Email"
                  placeholder="alsion@gmail.com"
                  value={formData.Email}
                  onChange={handleChange}
                  className="w-full bg-slate-700 rounded  focus:border-[#ffbf00] focus:ring-2 focus:ring-[#ffbf00]/30 text-base outline-none text-white py-1 placeholder:text-sm px-3 leading-8 placeholder:text-gray-300 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="Subject"
                  className="leading-7 text-sm font-medium text-gray-200"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="Subject"
                  name="Subject"
                  placeholder="Subject"
                  value={formData.Subject}
                  onChange={handleChange}
                  className="w-full bg-slate-700 rounded  focus:border-[#ffbf00] focus:ring-2 focus:ring-[#ffbf00]/30 text-base outline-none text-white py-1 placeholder:text-sm px-3 leading-8 placeholder:text-gray-300 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="Message"
                  className="leading-7 text-sm font-medium text-gray-200"
                >
                  Message
                </label>
                <textarea
                  id="Message"
                  name="Message"
                  placeholder="Write a message to our team.."
                  value={formData.Message}
                  onChange={handleChange}
                  className="w-full bg-slate-700 rounded  focus:border-[#ffbf00] focus:ring-2 focus:ring-[#ffbf00]/30 h-32 text-base outline-none text-white py-1 placeholder:text-sm px-3 resize-none leading-6 placeholder:text-gray-300 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
              <button className="text-gray-800 font-semibold bg-[#ffbf00] border-0 py-2 px-6 focus:outline-none hover:bg-yellow-500 rounded text-lg">
                {loading ? "Sending..." : "Submit"}
              </button>
            </div>
          </div>
        </section>
      </form>
    </>
  );
};

export default Contact;
